<template>
  <div class="hexagon-button font-Teko">
    <slot />
  </div>
</template>

<style>
.hexagon-button {
  position: relative;
  display: inline-block;
  padding: 0.1rem 2rem;
  font-size: 1.25rem;
  font-weight: bold;
  color: black;
  background: linear-gradient(to right, #00ff87, #00c9ff);
  border: 2px solid transparent;
  background-origin: border-box;
  border-radius: 0.5rem;
  cursor: pointer;
  text-align: center;
  overflow: hidden; /* Ensures the shine effect doesn't overflow the button */
}

.hexagon-button::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%; /* Starts off-screen */
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.6);
  transform: skewX(-30deg);
  z-index: 1;
  animation: shine 3s infinite ease-in-out; /* Continuous animation */
}

.hexagon-button::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #00ff87, #00c9ff);
  z-index: -1;
  border-radius: 0.5rem;
}

@keyframes shine {
  0% {
    left: -100%; /* Start off-screen on the left */
  }

  30% {
    left: 120%; /* End off-screen on the right */
  }
  100% {
    left: 120%; /* End off-screen on the right */

  }
}
</style>